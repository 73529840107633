const configSetting = {
  prod: {
    display: "",
    apiGateway: {
      REGION: "eu-central-1",
      URL: "https://tiefbau-backend.ewe-netz.de"
    },
    cognito: {
      REGION: "eu-central-1",
      USER_POOL_ID: "eu-central-1_0mKCLBt4B",
      APP_CLIENT_ID: "687709tph98i01l5hgqak89b9g",
      IDENTITY_POOL_ID: "eu-central-1:2937dd41-d2db-4830-a334-09fde608820d"
    }
  },
  stage: {
    display: " #STAGE",
    apiGateway: {
      REGION: "eu-central-1",
      URL: "https://stage.tiefbau-backend.ewe-netz.de"
    },
    cognito: {
      REGION: "eu-central-1",
      USER_POOL_ID: "eu-central-1_C9UiHfgFg",
      APP_CLIENT_ID: "6p79l7n2k4tni1jc2bjgshus61",
      IDENTITY_POOL_ID: "eu-central-1:21563d28-00bf-4613-b786-50797d3caf81"
    }
  },
  dev: {
    display: " #DEV",
    apiGateway: {
      REGION: "eu-central-1",
      URL: "https://dev.tiefbau-backend.ewe-netz.de"
    },
    cognito: {
      REGION: "eu-central-1",
      USER_POOL_ID: "eu-central-1_wqsgyJJyw",
      APP_CLIENT_ID: "29qj6hmkmvq67phi8b81gv1eth",
      IDENTITY_POOL_ID: "eu-central-1:441d0976-3a85-4842-8181-c2ecfa11a885"
    }
  }
};

// Default to stage if not set
let stage = process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE : 'dev';
//stage = 'stage';
//stage = 'prod';
let config = configSetting[stage];
config.stage = stage;

const exportConfig = {
  ...config
};

export default exportConfig;
