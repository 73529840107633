import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Footer from './Footer';
import MobileMenu from './containers/MobileMenu';
import Auth from "@aws-amplify/auth";
import Routes from "./Routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faSignOutAlt,
  faMapMarkedAlt,
  faCalculator,
  faCaretRight,
  faPalette,
  faSignature,
  faCog,
  faMapMarkerAlt,
  faThList,
  faBuilding,
  faUsers,
  faCode,
  faUserCircle,
  faTasks,
  faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import CookieConsent from "react-cookie-consent";
import { MayManageCompanies, MayManageUsers, MayViewDevOptions, MayViewFulfilledOrders } from "./components/RolePermissionChecker";
import config from './config';
import lang from './libs/languages';
import "./App.css";

const App = (props) => {

  const themes = {
    light: { // default
      name: 'Hell',
      css: 'light',
      logo: '/shovel.svg'
    },
    ewenetz: {
      name: 'EWE NETZ',
      css: 'ewenetz',
      logo: '/shovel.svg'
    },
    dark: {
      name: 'Dunkel',
      css: 'dark',
      logo: '/shovel_white.svg'
    },
    blue: {
      name: 'Dunkelblau',
      css: 'blue',
      logo: '/shovel_white.svg'
    }
  };

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [userMail, setUserMail] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userCompanyId, setUserCompanyId] = useState("");
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [theme, setTheme] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedTheme = localStorage.getItem('theme');
      return (storedTheme && storedTheme !== 'undefined') ? JSON.parse(storedTheme) : themes.light;
    }
    return themes.light;
  });
/*   const [languageCode, setLanguageCode] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedLanguage = localStorage.getItem('language');
      return (storedLanguage && storedLanguage !== 'undefined') ? JSON.parse(storedLanguage) : "de";
    }
    return "de";
  }); */
  const [languageCode, setLanguageCode] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedLanguage = localStorage.getItem('language');
      return (storedLanguage && storedLanguage !== 'undefined') ? JSON.parse(storedLanguage) : "de";
    }
    return "de";
  });
  const [language, setLanguage] = useState(lang.languages["de"]);

  useEffect(() => {
    onLoad();
    localStorage.setItem('theme', JSON.stringify(theme));
    localStorage.setItem('language', JSON.stringify(languageCode));
    setLanguage(lang.languages[languageCode]);
  }, [isAuthenticated, theme, language, languageCode]);

  const onLoad = async () => {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      Auth.currentAuthenticatedUser({
          bypassCache: true
        }).then(user => {
          setUserGroups(user.signInUserSession.idToken.payload["cognito:groups"]);
          setUserMail(user.signInUserSession.idToken.payload["email"]);
          setUserCompany(user.signInUserSession.idToken.payload["profile"]);
          setUserCompanyId(user.signInUserSession.idToken.payload["custom:identifier"]);
        })
        .catch(err => console.error(err));
    }
    catch (e) {
      if (e !== 'No current user' || e !== 'Refresh Token has expired') {
        console.error(e);
        console.error(e.message);
      }
    }

    setIsAuthenticating(false);
  }

  const handleLogout = async () => {
    await Auth.signOut();
    userHasAuthenticated(false);
    props.history.push("/login");
  }

  const changeTheme = () => {
    let themeKeys = Object.keys(themes);
    let currentThemeIndex = themeKeys.indexOf(theme.css);
    currentThemeIndex = (currentThemeIndex && currentThemeIndex !== -1) ? currentThemeIndex : 0;
    let nextThemeIndex = currentThemeIndex + 1 > themeKeys.length - 1 ? 0 : currentThemeIndex + 1;
    let nextTheme = themes[themeKeys[nextThemeIndex]];
    setTheme(nextTheme);

    // Im Kartenmodus die Karte hell/dunkel haben
    localStorage.setItem('mapDarkMode', JSON.stringify(false));//JSON.stringify((nextTheme.name === 'Hell' ? false : true)));
  }

  const changeLanguage = () => {
    let currentLangIndex = lang.availableLanguages.indexOf(languageCode);
    let nextLangIndex = currentLangIndex + 1 > lang.availableLanguages.length - 1 ? 0 : currentLangIndex + 1;
    setLanguageCode(lang.availableLanguages[nextLangIndex]);

    // Im Kartenmodus die Karte hell/dunkel haben
    localStorage.setItem('language', lang.availableLanguages[nextLangIndex]);//JSON.stringify((nextTheme.name === 'Hell' ? false : true)));
  }

  const renderNavBar = () => {
    return (
      <nav id="navbar">
        <div className="container" id="navbar-container" style={{height: '100%'}}>
          <LinkContainer to="/">
            <button id="logo" className="btn-link text-unselectable">
              <img
                src={theme && theme.logo ? theme.logo : '/shovel.svg'}
                width="30"
                height="30"
                className="d-inline-block"
                alt="Tiefbauportal"
              />
              <span style={{marginLeft: '1em', color: 'var(--logo-tiefbau-color)'}}>Tiefbau
              <span style={{color: 'var(--logo-portal-color)'}}>portal</span></span>
              <span style={{color: 'var(--danger-color)'}} className="d-none d-md-inline-block">{config.display}</span>
            </button>
          </LinkContainer>
          <div id="navbar-menu">
            {isAuthenticated ?
            <React.Fragment>
              <div id="navbar-icons" className="d-none d-lg-flex">
                <OverlayTrigger
                  placement="bottom"
                  delay="500"
                  overlay={
                    <Tooltip>
                      {language.karte}
                    </Tooltip>
                  }
                  >
                  <LinkContainer to="/map">
                    <button className="navbar-icon btn-link" eventkey={2}>
                      <span className="plateText">
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                      </span>
                    </button>
                  </LinkContainer>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  delay="500"
                  overlay={
                    <Tooltip>
                      {language.liste}
                    </Tooltip>
                  }
                  >
                  <LinkContainer to="/list">
                    <button className="navbar-icon btn-link" eventkey={3}>
                      <span className="plateText">
                        <FontAwesomeIcon icon={faThList} />
                      </span>
                    </button>
                  </LinkContainer>
                </OverlayTrigger>
                <MayManageCompanies userGroups={userGroups}>
                  <OverlayTrigger
                    placement="bottom"
                    delay="500"
                    overlay={
                      <Tooltip>
                        {language.firmen}
                      </Tooltip>
                    }
                    >
                    <LinkContainer to="/company">
                      <button className="navbar-icon btn-link" eventkey={3}>
                        <span className="plateText">
                          <FontAwesomeIcon icon={faBuilding} />
                        </span>
                      </button>
                    </LinkContainer>
                  </OverlayTrigger>
                </MayManageCompanies>
                <MayManageUsers userGroups={userGroups}>
                  <OverlayTrigger
                    placement="bottom"
                    delay="500"
                    overlay={
                      <Tooltip>
                        {language.anwender}
                      </Tooltip>
                    }
                  >
                    <LinkContainer to="/users">
                      <button className="navbar-icon btn-link" eventkey={3}>
                        <span className="plateText">
                          <FontAwesomeIcon icon={faUsers} />
                        </span>
                      </button>
                    </LinkContainer>
                  </OverlayTrigger>
                </MayManageUsers>
                <MayViewFulfilledOrders userGroups={userGroups}>
                  <OverlayTrigger
                    placement="bottom"
                    delay="500"
                    overlay={
                      <Tooltip>
                        {language.fertigmeldungen}
                      </Tooltip>
                    }
                  >
                    <LinkContainer to="/fulfilled">
                      <button className="navbar-icon btn-link" eventkey={3}>
                        <span className="plateText">
                          <FontAwesomeIcon icon={faTasks} />
                        </span>
                      </button>
                    </LinkContainer>
                  </OverlayTrigger>
                </MayViewFulfilledOrders>
                <MayViewDevOptions userGroups={userGroups}>
                  <OverlayTrigger
                    placement="bottom"
                    delay="500"
                    overlay={
                      <Tooltip>
                        {language.termine}
                      </Tooltip>
                    }
                  >
                    <LinkContainer to="/appointments">
                      <button className="navbar-icon btn-link" eventkey={5}>
                        <span className="plateText">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </span>
                      </button>
                    </LinkContainer>
                  </OverlayTrigger>
                </MayViewDevOptions>
              </div>
              <button
                id="language-switch"
                className="btn-link d-none d-lg-block"
                onClick={() => changeLanguage()}>
                  <small>{languageCode}</small>
              </button>
              <React.Fragment>
                <DropdownButton
                  className="menuButton d-none d-lg-inline-block"
                  alignRight
                  title={<span className="plateText"><FontAwesomeIcon style={{marginRight: '0.5em'}} icon={faUserCircle} /></span>}//{userMail}
                  size="sm"
                  id="dropdown-menu-align-right"
                >
                  <Dropdown.ItemText style={{padding:'1em 0', background: 'var(--plate-background-color)', textAlign: 'center'}}>
                    <span className="plateText">
                    <OverlayTrigger
                      placement="bottom"
                      delay="500"
                      overlay={
                        <Tooltip>
                          {userCompany} ({userCompanyId})
                        </Tooltip>
                      }
                      >
                        <FontAwesomeIcon size="lg" icon={faUserCircle} />
                      </OverlayTrigger>
                      <br /><span className="small">{userMail}</span>
                    </span>
                  </Dropdown.ItemText>
                  <Dropdown.Divider />
                  <LinkContainer to="/pricelist">
                    <Dropdown.Item eventKey="1">
                      <span className="normalText"><FontAwesomeIcon icon={faCalculator} />&nbsp;&nbsp;&nbsp;Leistungsverzeichnis</span>
                    </Dropdown.Item>
                  </LinkContainer>
                  <Dropdown.Item eventKey="1" href="https://www.ewe-netz.de/geschaeftskunden/service/leitungsplaene-abrufen#mC3E" target="_blank" rel="noopener noreferrer">
                    <span className="normalText"><FontAwesomeIcon icon={faMapMarkedAlt} />&nbsp;&nbsp;&nbsp;Internet Bauauskunft</span>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <LinkContainer to="/settings">
                    <Dropdown.Item eventKey="1">
                      <span className="normalText"><FontAwesomeIcon icon={faCog} />&nbsp;&nbsp;&nbsp;Einstellungen</span>
                    </Dropdown.Item>
                  </LinkContainer>
                  <Dropdown.Divider />
                  <Dropdown.ItemText className="d-flex justify-content-between">
                    <div>
                      <span className="normalText"><FontAwesomeIcon icon={faPalette} />&nbsp;&nbsp;&nbsp;{theme.name}</span>
                    </div>
                    <button className="btn-link h-100 p-0 m-0" style={{width: '20%'}} onClick={() => changeTheme()}>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </button>
                  </Dropdown.ItemText>
                  <Dropdown.Divider />
                  <MayViewDevOptions userGroups={userGroups}>
                    <LinkContainer to="/csv">
                      <Dropdown.Item eventKey="1">
                      <span className="normalText"><FontAwesomeIcon icon={faCode} />&nbsp;&nbsp;&nbsp;CSV-to-JSON Tool</span>
                      </Dropdown.Item>
                    </LinkContainer>
                    <Dropdown.Divider />
                  </MayViewDevOptions>
                  <Dropdown.Item eventKey="2" onClick={handleLogout}>
                    <span className="normalText"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;&nbsp;Logout</span>
                  </Dropdown.Item>
                </DropdownButton>
              </React.Fragment>
            </React.Fragment>
              : <div className="w-100 d-none d-lg-flex justify-content-end">
                  <LinkContainer to="/signup">
                    <button className="navbar-icon btn-link" eventkey={6}>
                      <FontAwesomeIcon icon={faSignature} />
                      &nbsp;&nbsp;{language.registrieren}
                    </button>
                  </LinkContainer>
                </div>
            }
            <button
              id="navbar-mobilemenu-button"
              className="btn-link d-lg-none"
              onClick={() => setShowMobileMenu(true)}>
              <FontAwesomeIcon
                icon={faBars}
                size="2x"
              />
            </button>
          </div>
        </div>
      </nav>
    );
  }

  return (!isAuthenticating &&
    <div className={"App Theme" + (theme ? " " + theme.css : "")}>
      <MobileMenu isAuthenticated={isAuthenticated} show={showMobileMenu} onHide={() => setShowMobileMenu(false)} onLogout={handleLogout} />
      {renderNavBar()}
      <div id="page-container">
        <div id="content-wrap">
          <Routes appProps={{ isAuthenticated, userHasAuthenticated, userGroups, config, theme, changeTheme, language }} />
        </div>
        <Footer config={config} changeTheme={changeTheme} lang={language} isAuthenticated={isAuthenticated} />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Verstanden"
        cookieName="acceptedCookie"
        style={{ background: "#000", zIndex: '2000' }}
        buttonStyle={{margin: '10px'}}
        buttonClasses="btn btn-success"
        disableButtonStyles={true}
        containerClasses="cookieconsent-container"
        expires={150}
        debug={false}
      >
        {language.cookieconsentText}
      </CookieConsent>
    </div>
  );
}

export default withRouter(App);
