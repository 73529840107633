import { may } from "../libs/permissionLib.js";

export function MayCreateOrder({ ...props }) {
  return may('mayCreateOrder', props.userGroups) ? props.children : "";
}

export function MayDeleteOrder({ ...props }) {
  return may('mayDeleteOrder', props.userGroups) ? props.children : "";
}

export function MayUpdateOrder({ ...props }) {
  return may('mayUpdateOrder', props.userGroups) ? props.children : "";
}

export function MayManageCompanies({ ...props }) {
  return may('mayManageCompanies', props.userGroups) ? props.children : "";
}

export function MayManageUsers({ ...props }) {
  return may('mayManageUsers', props.userGroups) ? props.children : "";
}

export function MayBillOrder({ ...props }) {
  return may('mayBillOrder', props.userGroups) ? props.children : "";
}

export function MayViewDevOptions({ ...props }) {
  return may('mayViewDevOptions', props.userGroups) ? props.children : "";
}

export function MayViewFulfilledOrders({ ...props }) {
  return may('mayViewFulfilledOrders', props.userGroups) ? props.children : "";
}