import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSignOutAlt, faCog, faMapMarkedAlt, faListOl, faSignature } from '@fortawesome/free-solid-svg-icons';
import "./MobileMenu.css";

export default function MobileMenu({...props}) {

    return (
      <div className={props.show ? "mobileMenu showSlide" : "mobileMenu hideSlide"}>
        <div>
          <button 
            variant="link"
            className="closeButton"
            onClick={props.onHide}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="mobileMenuLogo">
          <img
            src="/shovel_white.svg"
            width="75"
            height="75"
            className="align-top"
            alt="Tiefbauportal"
          />
          <br />Tiefbauportal
        </div>
        <div onClick={props.onHide} className="menuListContainer">
          {props.isAuthenticated ?
          <ul className="menuList">
            <LinkContainer to="/list">
              <li>
                <span>
                  <FontAwesomeIcon icon={faListOl} />
                </span>
                Liste
              </li>
            </LinkContainer>
            <LinkContainer to="/map">
              <li>
                <span>
                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                </span>
                Karte
              </li>
            </LinkContainer>
            <LinkContainer to="/settings">
              <li>
                <span>
                  <FontAwesomeIcon icon={faCog} />
                </span>
                Einstellungen
              </li>
            </LinkContainer>
            <li onClick={props.onLogout}>
              <span>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </span>
              Logout
            </li>
          </ul>
          :
          <ul className="menuList">
            <LinkContainer to="/signup">
              <li>
                <span>
                  <FontAwesomeIcon icon={faSignature} />
                </span>
                Registrieren
              </li>
            </LinkContainer>
          </ul>
          }
        </div>
      </div>
    );
}
