import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export default function Footer(props) {
  return (
    <footer id="footer" className="scratch-plate">
      <section className="container scratch-plate">
        <div className="row">
          <article className="text-center text-sm-left col-12 col-sm-4">
            <p className="footerTitle">EWE NETZ GmbH</p>
            <p className="small">
              Cloppenburger Straße 302<br />
              26133 Oldenburg<br />
              <a className="scratch-plate" href="http://www.ewe-netz.de" target="_blank" rel="noopener noreferrer">ewe-netz.de</a>
            </p>
          </article>
          <article className="text-center col-12 col-sm-4">
            {props.config.stage === "dev" ?
            <p className="small">
              <button className="btn-link" style={{backgroundColor: 'none'}} onClick={() => {props.changeTheme()}}>
                <img alt="" src="/logo-ewe-netz.svg" height="25" width="140" />
              </button>
            </p>
            : 
            <p className="small">
              <img src="/logo-ewe-netz.svg" alt="" height="25" width="140" />
            </p>
            }
          </article>
          <article className="text-center text-sm-right col-12 col-sm-4">
            <p className="footerTitle">{props.lang.sonstiges}</p>
            <p className="small">
              <a className="scratch-plate" href="https://www.ewe-netz.de/vertragspartner/" target="_blank" rel="noopener noreferrer">Vertragspartner-Web</a><br />
              {props.isAuthenticated &&
              <Link className="scratch-plate d-none d-md-inline" to="/swagger">
              {props.lang.apiDokumentation}<br />
              </Link>
              }
              <a className="scratch-plate" href="https://www.ewe-netz.de/meta/datenschutz" target="_blank" rel="noopener noreferrer">{props.lang.datenschutzerklaerung}</a><br />
              <a className="scratch-plate" href="https://www.ewe-netz.de/meta/impressum" target="_blank" rel="noopener noreferrer">{props.lang.impressum}</a>
            </p>
          </article>
        </div>
      </section>
    </footer>
  );
}
