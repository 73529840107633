const lang = {
    availableLanguages: ["de", "en"],
    languages: {
        de: {
            abrechnung: "Abrechnung",
            anhaenge: "Anhänge",
            auftrag: "Auftrag",
            auftraege: "Aufträge",
            apiDokumentation: "API Dokumentation",
            anwender: "Anwender",
            cookieconsentText: "Diese Webseite verwendet Cookies zur Verbesserung der Nutzererfahrung.",
            dateiupload: "Dateiupload",
            datenschutzerklaerung: "Datenschutzerklärung",
            datum: "Datum",
            einstellungen: "Einstellungen",
            fertigmeldungen: "Fertigmeldungen",
            firmen: "Firmen",
            impressum: "Impressum",
            internetBauauskunft: "Internet Bauauskunft",
            karte: "Karte",
            leistungsverzeichnis: "Leistungsverzeichnis",
            logout: "Logout",
            liste: "Liste",
            ort: "Ort",
            registrieren: "Registrieren",
            sonstiges: "Sonstiges",
            strasse: "Straße",
            suchFeldPlaceholder: "Suche...",
            termine: "Termine",
            uebersicht: "Übersicht",
            vorgang: "Vorgang",
            zurueck: "Zurück",
            kunde: "Kunde",
            name: "Name",
            tel: "Tel.",
            mail: "Mail",
            techndaten: "Techn. Daten",
            technobjnr: "Techn. Obj. Nr.",
            technobjstrasse: "Techn. Obj. Straße",
            technobjort: "Techn. Obj. Ort",
            onsstrasse: "ONS Straße",
            onsort: "ONS Ort",
            historie: "Historie",
            kurztext: "Kurztext",
            anschlussart: "Anschlussart",
            kommentar: "Kommentar"
        },
        en: {
            abrechnung: "Billing",
            anhaenge: "Attachments",
            auftrag: "Order",
            auftraege: "Orders",
            apiDokumentation: "API Documentation",
            anwender: "User",
            cookieconsentText: "This Website uses Cookies to improve user experience.",
            dateiupload: "Upload",
            datenschutzerklaerung: "Data Protection",
            datum: "Date",
            einstellungen: "Settings",
            fertigmeldungen: "Fulfilments",
            firmen: "Companies",
            impressum: "Imprint",
            internetBauauskunft: "Construction Information",
            karte: "Map",
            leistungsverzeichnis: "Pricelist",
            logout: "Logout",
            liste: "List",
            registrieren: "Register",
            sonstiges: "Other",
            strasse: "Street",
            suchFeldPlaceholder: "Search...",
            termine: "Appointments",
            uebersicht: "Overview",
            vorgang: "Ordertype",
            zurueck: "Back",
            kunde: "Customer",
            name: "Name",
            tel: "Phone",
            ort: "City",
            mail: "Mail",
            techndaten: "Techn. Object",
            technobjnr: "Techn. Obj. Nr.",
            technobjstrasse: "Techn. Obj. Street",
            technobjort: "Techn. Obj. City",
            onsstrasse: "ONS Street",
            onsort: "ONS City",
            historie: "History",
            kurztext: "Shorttext",
            anschlussart: "Connection Type",
            kommentar: "Comment"
        }
    }
}

export default lang;