import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import "./LoadingContainer.css";

export default function LoadingContainer(props) {
    return (
        <article className={"container loader flexVCenterContainer" + (props.className ? (" " + props.className) : "")}>
            <div className="justify-content-center">
                <div className="loaderIcon">
                    <PulseLoader
                      size={props.size ? props.size : 10}
                      margin={props.margin ? props.margin : 4}
                      loading={true}
                    />
                </div>
            </div>
        </article>
    );
}
