const roles = {
  'vf-mitarbeiter': {
    description: "Vertragsfirma Sachbearbeiter",
    isGrantable: true,
    mayCreateOrder: false,
    mayDeleteOrder: false,
    mayUpdateOrder: false,
    mayBillOrder: false,
    mayManageUsers: false,
    mayManageUsersOfOwnCompany: false,
    mayManageCompanies: false,
    mayViewFulfilledOrders: false,
    mayViewDevOptions: false
  },
  'ewenetz-mitarbeiter': {
    description: "EWE NETZ Mitarbeiter",
    isGrantable: true,
    mayCreateOrder: false,
    mayDeleteOrder: false,
    mayUpdateOrder: false,
    mayBillOrder: true,
    mayManageUsers: true,
    mayManageUsersOfOwnCompany: false,
    mayManageCompanies: true,
    mayViewFulfilledOrders: false,
    mayViewDevOptions: false
  },
  'ewenetz-entwickler': {
    description: "EWE NETZ Entwickler",
    isGrantable: false,
    mayCreateOrder: true,
    mayDeleteOrder: false,
    mayUpdateOrder: true,
    mayBillOrder: true,
    mayManageUsers: true,
    mayManageUsersOfOwnCompany: false,
    mayManageCompanies: true,
    mayViewFulfilledOrders: true,
    mayViewDevOptions: true
  },
  'rpa-bot': {
    description: "RPA Roboter",
    isGrantable: false,
    mayCreateOrder: false,
    mayDeleteOrder: false,
    mayUpdateOrder: false,
    mayBillOrder: true,
    mayManageUsers: false,
    mayManageUsersOfOwnCompany: false,
    mayManageCompanies: false,
    mayViewFulfilledOrders: true,
    mayViewDevOptions: false
  }
};

// Durchsucht alle vorhandenen Gruppen nach der Erlaubnis Aufträge anzulegen. Default = false.
export function may(permission, userGroups) {
  if (!userGroups) {
    return false;
  }
  let returnVal = false;
  userGroups.forEach(function(group, index) {
    if (roles[group]) {
      if (roles[group][permission] === true) {
        returnVal = true;
      }
    }
  });
  return returnVal;
}

export function getRolesAsOptionTable() {
  const result = [];
  Object.keys(roles).forEach((roleKey) => {
    if(roles[roleKey].isGrantable) {
      result.push({
        value: roleKey,
        label: roles[roleKey].description
      });
    }
  });
  return result;
}

export function getRoleDescription(identifier) {
  return roles[identifier].description;
}
