import React, {lazy, Suspense}  from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import AppliedRoute from "./components/AppliedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import LoadingContainer from "./components/LoadingContainer";

const OrderMap = lazy(() => import( "./containers/map/OrderMap" ));
const Order = lazy(() => import( "./containers/order/Order" ));
const OrderList = lazy(() => import( "./containers/list/OrderList" ));
const Users = lazy(() => import( "./containers/users/Users" ));
const CompanyList = lazy(() => import( "./containers/company/CompanyList" ));
const FulfilledOrders = lazy(() => import( "./containers/fulfillments/FulfilledOrders" ));
const AppointmentList = lazy(() => import( "./containers/appointments/AppointmentList" ));
const PriceList = lazy(() => import( "./containers/pricelist/PriceList" ));
const Login = lazy(() => import( "./containers/Login" ));
const Signup = lazy(() => import( "./containers/Signup" ));
const Settings = lazy(() => import( "./containers/Settings" ));
const ChangePassword = lazy(() => import( "./containers/ChangePassword" ));
const CSVtoJSON = lazy(() => import( "./containers/CSVtoJSON" ));
const ResetPassword = lazy(() => import( "./containers/ResetPassword" ));
const NotFound = lazy(() => import( "./containers/NotFound" ));
const Swagger = lazy(() => import( "./containers/Swagger" ));

export default function Routes({ appProps }) {

  return (
    <Suspense fallback={
      <div className="h-100">
        <LoadingContainer />
      </div>
    }>
      <Switch>
        {appProps.isAuthenticated ? 
          <AuthenticatedRoute path="/" exact component={OrderList} appProps={appProps} />
          :
          <UnauthenticatedRoute path="/" exact component={Login} appProps={appProps} />
        }
        <UnauthenticatedRoute path="/" exact component={!appProps.isAuthenticated ? Login : OrderList} appProps={appProps} />
        <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
        <UnauthenticatedRoute path="/signup" exact component={Signup} appProps={appProps} />
        <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} appProps={appProps} />
        <AppliedRoute path="/csv" exact component={CSVtoJSON} appProps={appProps} />
        <AppliedRoute path="/swagger" exact component={Swagger} appProps={appProps} />
        <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} appProps={appProps} />
        <AuthenticatedRoute path="/settings" exact component={Settings} appProps={appProps} />
        <AuthenticatedRoute path="/list" exact component={OrderList} appProps={appProps} />
        <AuthenticatedRoute path="/map" exact component={OrderMap} appProps={appProps} />
        <AuthenticatedRoute path="/map/:id" exact component={OrderMap} appProps={appProps} />
        <AuthenticatedRoute path="/list/:page?" exact component={OrderList} appProps={appProps} />
        <AuthenticatedRoute path="/order/:id" exact component={Order} appProps={appProps} />
        <AuthenticatedRoute path="/order/:id/:tab" exact component={Order} appProps={appProps} />
        <AuthenticatedRoute path="/company" exact component={CompanyList} appProps={appProps} />
        <AuthenticatedRoute path="/appointments" exact component={AppointmentList} appProps={appProps} />
        <AuthenticatedRoute path="/pricelist" exact component={PriceList} appProps={appProps} />
        <AuthenticatedRoute path="/fulfilled" exact component={FulfilledOrders} appProps={appProps} />
        <AuthenticatedRoute path="/users" exact component={Users} appProps={appProps} />

        { /* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}
