import React from 'react';
import ReactDOM from 'react-dom';
import config from './config';
import './bootstrap.min.css';
import './index.css';
import './themes.css';
import './leaflet.css';
import App from './App';
import { unregister } from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
//import Auth from "@aws-amplify/auth";
//import API from "@aws-amplify/api";
import "@fontsource/cabin" // Defaults to weight 400.
import "@fontsource/yanone-kaffeesatz/600.css"
import("@aws-amplify/api").then(({ API }) => {
  API.configure({
    API: {
      endpoints: [{
        name: "tiefbauportal-endpoint",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }, ]
    }
  });
});
import("@aws-amplify/auth").then(({ Auth }) => {
  Auth.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    }
  });
});
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
